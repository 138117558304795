<template>
	<div class='displayFlex-1 p20px OverflowAuto h70%'>
		<!-- 2.1节点公用 -->
		<common-view></common-view>
		<!-- 2.2节点公用 -->
		<common-proposal :isDisabled="true"></common-proposal>
	</div>
</template>

<script>
import CommonProposal from '../../views/KYC/Recommendations/components/CommonProposal.vue';
import CommonView from '../../views/KYC/Recommendations/SummayOfKYCDetail/CommonView.vue';

export default {
	components: { CommonView, CommonProposal },
	data () {
		return {
		};
	},
	computed: {
	},
	watch: {
	},
	methods: {
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
	beforeCreate () { }, //生命周期 - 创建之前
	beforeMount () { }, //生命周期 - 挂载之前
	beforeUpdate () { }, //生命周期 - 更新之前
	updated () { }, //生命周期 - 更新之后
	beforeDestroy () { }, //生命周期 - 销毁之前
	destroyed () { }, //生命周期 - 销毁完成
	activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='css' scoped>
</style>