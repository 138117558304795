/*
 *@Date: 2022-05-25 15:07:30
 *@Description: KYC 汇总
*/
<template>
	<div class='displayFlex flexColumn h100 boxSizing'>
		<Navbar :showBack="true" backgroundColor="#FF9941" color="#fff">KYC</Navbar>
		<!-- 顶部固定内容 ID、Create Date等-->
		<el-form inline class="displayFlex flexSpace selectForm" style="padding: 15px;">
			<el-form-item label="ID">{{kycAllData.kyc_no}}</el-form-item>
			<el-form-item label="Create Date">{{kycAllData.creat_time | dateFmt('MM/DD/YYYY')}}</el-form-item>
			<el-form-item label="Status">{{$dictStatus(kycAllData.submit_audit_status)}}</el-form-item>
			<el-form-item v-if="kycAllData.kyc_pdf_attachment">
				<el-button @click="downLoadPDF(kycAllData.kyc_pdf, kycAllData.kyc_no)" size="medium" type="info">KYC PDF</el-button>
			</el-form-item>
			<el-form-item>
				<el-radio-group v-model="clientName" @change="clientChange" size="medium" fill="#FF9941">
					<el-radio-button v-for="item in clientNames" :label="item" :key="item"></el-radio-button>
				</el-radio-group>
			</el-form-item>
		</el-form>
		<!-- 两个tab页 Basic、Submissions-->
		<el-tabs v-model="activeName" :stretch=true class="my-tab">
			<el-tab-pane label="Basic" name="Basic"></el-tab-pane>
			<el-tab-pane label="Submissions" name="Submissions"></el-tab-pane>
		</el-tabs>
		<!-- 各tab页对应的详情页面 -->
		<component :is="list[item]"></component>
	</div>
</template>

<script>
import Navbar from "components/Navbar.vue";
import Basic from './KYCBasicpage.vue';
import Submissions from "./KYCSubmissions.vue";
export default {
	components: { Navbar, Basic, Submissions },
	data () {
		return {
			list: [Basic, Submissions],
			item: 0,
			activeName: 'Basic',
			clientName: this.$store.state.User.clientNameArr[0],
			clientNames: this.$store.state.User.clientNameArr,
			kycAllData: {},
			dataValue: {}
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		kycClient () {
			this.getData()
		},
		activeName (val) {
			if (val === 'Basic') {
				this.item = 0
			} else {
				this.item = 1
			}
		}
	},
	methods: {
		getData () {
			this.kycAllData = this.$store.state.KYC.kycAllData.kyc
			this.dataValue = this.kycAllData.client[this.kycClient]
		},
		downLoadPDF (url, kyc_no) {
			let newUrl = url + '&kycNumber=' + kyc_no
			window.open(newUrl, '_blank')
		},
		// 两个用户时切事件
		clientChange (val) {
			// kycClient
			this.$store.commit('SET_KYCCLIENT', this.clientNames.indexOf(val))
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.getData()
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
	beforeCreate () { }, //生命周期 - 创建之前
	beforeMount () { }, //生命周期 - 挂载之前
	beforeUpdate () { }, //生命周期 - 更新之前
	updated () { }, //生命周期 - 更新之后
	beforeDestroy () { }, //生命周期 - 销毁之前
	destroyed () { }, //生命周期 - 销毁完成
	activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.selectForm /deep/ .el-form-item__label, .selectForm /deep/ .el-form-item__content {
	font-size: 18px;
}
</style>